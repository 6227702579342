import React, {useEffect, useRef} from "react";
import "./Hobby.css"; // Import the CSS file
import singingmv from "../../assets/singing.mov";
// import singingmv2 from "../../assets/singing2.mov";
// import singingmv3 from "../../assets/singing3.mov";
// import singingmv4 from "../../assets/singing4.mov";
import cat1 from "../../assets/images/cat1.jpg";
import cat2 from "../../assets/images/cat2.jpg";
import dog from "../../assets/images/dog.jpg";
import CardComponent from "../../components/CardComponent/CardComponent";

const Hobby = ({showModal, setShowModal, children}) => {
  const videoRef = useRef(null);
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play();
    }
  }, []);
  return (
    <>
      {showModal ? (
        <div className={`modal-overlay ${showModal ? "modal-open" : ""}`}>
          <div className={`modal-content ${showModal ? "modal-zoom-in" : ""}`}>
            <div className="modal-header" >
              <h2 style={{color:"black"}} > ABOUT ME</h2>

              <button
                className="modal-close"
                onClick={() => setShowModal(false)}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">{children}</div>
            <div style={{color:"black"}}>
              <div >
                <h4 >💼 PROFESSIONAL LIFE</h4>
                <p>
                  - I'm a Software Engineer passionate about full stack
                  development and AI Technologies
                </p>
                <p>- I'm also experienced in project management</p>
                <p>- I'm self-driven and enjoy problem-solving</p>
              </div>
              <h4>🎨 PERSONAL LIFE</h4>
              <p style={{fontSize: "16px", fontWeight: "700"}}>
                {" "}
                 SINGING 🎤 🎵{" "}
              </p>
              <p>
                I participated in a college singing competition and won the
                Excellent Prize.{" "}
              </p>
              <p> I also performed as a guest singer at college events.</p>
              <p style={{fontSize: "14px", fontWeight: "700", color: "gray"}}>
                Cover: More Difficult Than It Seems | Song by Tiger Huang | Chinese
              </p>
              <video style={{width: "100%"}} ref={videoRef} controls loop>
                <source src={singingmv} type="video/mp4" />
              </video>
              {/* <p style={{fontSize: "14px", fontWeight: "700", color: "gray"}}>
                Cover: Speechless | Song by Naomi Scott | English
              </p>
              <video style={{width: "100%"}} ref={videoRef} controls loop>
                <source src={singingmv2} type="video/mp4" />
              </video>
              <p style={{fontSize: "14px", fontWeight: "700", color: "gray"}}>
                Cover: Hourglass of Memories | Song by G.E.M | Chinese
              </p>
              <video style={{width: "100%"}} ref={videoRef} controls loop>
                <source src={singingmv3} type="video/mp4" />
              </video>
              <p style={{fontSize: "14px", fontWeight: "700", color: "gray"}}>
                Cover: Heaven | Song by Ailee Song | Korean
              </p>
              <video style={{width: "100%"}} ref={videoRef} controls loop>
                <source src={singingmv4} type="video/mp4" />
              </video> */}
            </div>
            <div style={{color:"black"}}>
              <p style={{fontSize: "16px", fontWeight: "700"}}>
                 MAKING BOBA 🧋{" "}
              </p>
              <p>I love drinking boba </p>
              <p> Rose Jasmine Milk Tea is so far my favorite</p>
            </div>
            <div style={{color:"black"}}>
              <p style={{fontSize: "16px", fontWeight: "700"}}>
                 MY FURRY FRIENDS 🐱🐱🐶{" "}
              </p>
              <CardComponent />
              <p> </p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Hobby;
