import React from "react";
import "./SubNav.css";
import canvas from "../../assets/images/canvas.png";
import AppAcademy from "../../assets/images/Appacademylogo.png";
import freelance from "../../assets/images/freelance.png";
import giampolini from "../../assets/images/giampolini.png";
import ips from "../../assets/images/ips.jpeg";
import headstarter from "../../assets/images/headstarter.png";
import {Fade} from "react-reveal";

const SubNav = () => {
  return (
    <div className="container">
      <div className="timeline">
        <Fade right>
          <div className="box">
            <div className="date">July - current 2024</div>
            <div className="imgBx" style={{backgroundColor: "black"}}>
              <img
                src={headstarter}
                style={{width: "100%", height: "auto"}}
                alt="Gallery Item 1"
              />
            </div>
            <div className="content">
              <h2 style={{fontSize: "22px"}}>
                Software Engineering Resident - AI
              </h2>

              <p>
                AI Tools - RAG | Pinecone | LangChain Vector Embeddings | AWS
                Bedrock | LLama 3.1 | Claude 3 | OpenAI, HuggingFace
                <p>
                  Other tools - TypeScript | NextJS | Stripe | MongoDB | Clerk |
                  Firebase | React Camera Pro | AWS EC2
                </p>
                <p>
                  ● Led full-stack development for 8+ AI software, Integrated
                  Prompt Engineering & LLMs, following Agile and CI/CD practices
                </p>
                <p>
                  ● Automated Image Classification and inventory update with
                  OpenAI GPT Vision and improved app scalability with Docker,
                  resulting in a surge of 110+ visitors on the 1st day of launch
                  live
                </p>
                <p>
                  ● Integrated a RAG (Retrieval-Augmented Generation) pipeline
                  with Pinecone, Langchain that improved data search accuracy.
                  Engineered an ETL pipeline by developing a Playwright Web
                  Scraper and Python/Flask APIs for automated data collection,
                  cleaning, and upserting to Pinecone database. Implemented
                  Sentiment Analysis with OpenAI demo
                </p>
                <p>
                  ● Spearheaded a 30-hr Hackathon project, implementing a
                  Serverless Backend Architecture with AWS DynamoDB, Lambda, S3,
                  API Gateway, resolving critical systemic errors that ensured
                  on-time project deployment
                </p>
              </p>
              <p></p>
            </div>
          </div>
        </Fade>
        <Fade left>
          <div className="box">
            <div className="date">Feb - Jun 2024</div>
            <div className="imgBx">
              <img src={canvas} alt="Gallery Item 1" />
            </div>
            <div className="content">
              <h2>Software Engineer</h2>
              <p>
                Next.js 14 | TypeScript | PostgreSQL | Prisma ORM | Zod | Axios
                | Figma | MaterialUI | FullCalendar | Grafana
              </p>
              <p>
                ● Led full-stack development and system design, transforming
                conceptual ideas into a functional fleet management software
              </p>
              <p>
                ● Implemented interactive Calendar Timeline with event
                drag-and-drop and CRUD functionalities, successfully resolving a
                year-long robot management issue, exceeding stakeholder
                expectations
              </p>
              <p>
                ● Developed SQL-based performance metrics & Grafana Dashboards,
                significantly improved customer support and drove sales
              </p>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className="box">
            <div className="date">Jun 2022 – Present</div>
            <div className="imgBx">
              <img
                style={{height: "100%", width: "auto"}}
                src={freelance}
                alt="Gallery Item 3"
              />
            </div>
            <div className="content">
              <h2>Full Stack Software Developer - freelance</h2>

              <p>
                Python3 | JavaScript ES5/ES6+ | MERN Stack | RESTful API |
                SQL/NoSQL Databases | jQuery | Ajax | Axios | HTML | CSS | PHP |
                OOP | GUI | Pandas
              </p>
              <p>
                Leading the re-development of Direct-r’s web platform using
                Next.js, MongoDB, TypeScript, and AWS. Migrated existing .NET/C#
                backend and redefined back-end API services that improved app
                scalability and maintainability
              </p>
              <p>
                ● Architected an AI-powered project management software as
                founding engineer, using Next.js, TypeScript, Python/Flask, and
                MongoDB for full-stack implementation. Animated responsive UI
                with Figma, Framer Motion, Tailwind CSS live
              </p>
              <p>
                ● Implemented NLP (Natural Language Processing) functionalities,
                transitioning from IBM Watson to Hugging Face Transformers for
                Speech-To-Text (STT) conversion, improving STT accuracy and
                responsiveness by 40%
              </p>
              <p>
                ● Implemented a RAG pipeline with Hugging Face Embeddings and
                Pinecone for efficient semantic search. Integrated Gemini LLM to
                analyze user queries and deliver context-aware responses with
                low latency
              </p>
            </div>
          </div>
        </Fade>
        <Fade left>
          <div className="box">
            <div className="date">May - Oct 2023</div>
            <div className="imgBx">
              <img
                style={{height: "100%", width: "auto"}}
                src={AppAcademy}
                alt="Gallery Item 2"
              />
            </div>
            <div className="content">
              <h2>Software Engineer In Training</h2>
              <p>
                JavaScript | React | Redux | Postgres | Python | Flask | Express
                | AWS
              </p>
              <p>
                {" "}
                ● Ranked 1st in performance among 100+ developers, continuously
                exceeding project requirements with 3+ additional features
              </p>
              <p>
                ● Engineered scalable solutions with Java and Spring, applying
                OOP and TDD principles, with robust JUnit Testing in Maven
              </p>
              <p>
                ● Mentored fellow software engineers and supported them in
                debugging and problem-solving
              </p>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className="box">
            <div className="date">Mar 2021 – Nov 2021</div>
            <div className="imgBx">
              <img
                style={{height: "100%", width: "auto"}}
                src={ips}
                alt="Gallery Item 5"
              />
            </div>
            <div className="content">
              <h2>Project Engineer</h2>
              <p>Project QA/QC | Project Management, Planning & Scheduling</p>
            </div>
          </div>
        </Fade>
        <Fade left>
          <div className="box">
            <div className="date">Oct 2017 – Dec 2020</div>
            <div className="imgBx">
              <img
                style={{height: "100%", width: "auto"}}
                src={giampolini}
                alt="Gallery Item 4"
              />
            </div>
            <div className="content">
              <h2>Senior Project Engineer</h2>
              <p>
                Visual Basic for Application | Miscrosoft Access | Project
                Management
              </p>
              <p>
                ● Independently designed and developed Supply Chain Management
                Tool using VBA (MS Visual Basic) & Access Database, automating
                record keeping and calculations. Developed automated scripts for
                cost forecasting using linear regression
              </p>
              <p>
                ● Proposed engineering solutions to a multi-million dollar
                project that mitigated legal risks and saved $~300K
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default SubNav;
