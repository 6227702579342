import React from "react";
import "./App.scss";
import Main from "./containers/Main";
import Cursor from "./components/Cursor/Cursor";
import Firework from "./components/Firework/Firework";


function App() {
  
  
  return (
    <div>
  
      <Main />
    </div>
  );
}

export default App;
