import React, { useEffect, useRef } from "react";
import "./CardComponent.css";
import nian from "../../assets/images/nian.png";
import se from "../../assets/images/se.png";
import ku from "../../assets/images/ku.png";

const CardComponent = () => {
  const cardsRef = useRef(null);
  const imagesRef = useRef([]);
  const backgroundsRef = useRef([]);

  useEffect(() => {
    const cards = cardsRef.current;
    const images = imagesRef.current;
    const backgrounds = backgroundsRef.current;
    const range = 40;

    const calcValue = (a, b) => ((a / b) * range - range / 2).toFixed(1);

    let timeout;
    const handleMouseMove = e => {
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }

      timeout = window.requestAnimationFrame(() => {
        const yValue = calcValue(e.y, window.innerHeight);
        const xValue = calcValue(e.x, window.innerWidth);

        cards.style.transform = `rotateX(${yValue}deg) rotateY(${xValue}deg)`;

        images.forEach(image => {
          image.style.transform = `translateX(${-xValue}px) translateY(${yValue}px)`;
        });

        backgrounds.forEach(background => {
          background.style.backgroundPosition = `${xValue * 0.45}px ${
            -yValue * 0.45
          }px`;
        });
      });
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="cards" ref={cardsRef}>
      <div className="card card_one">
        <div
          className="card__bg"
          ref={el => (backgroundsRef.current[0] = el)}
        >
          <p className="sound">Woof!</p>
        </div>
        <img
          alt="card_image"
          className="card__img"
          ref={el => (imagesRef.current[0] = el)}
          src={ku}
        />
        <div className="card__text">
          {/* <p className="card__title">Frontend</p> */}
        </div>
      </div>
      <div className="card card_two">
        <div
          className="card__bg"
          ref={el => (backgroundsRef.current[1] = el)}
        >
          <p className="sound">Meow!</p>
        </div>
        <img
          alt="card_image"
          className="card__img"
          ref={el => (imagesRef.current[1] = el)}
          src={nian}
        />
        <div className="card__text">
          {/* <p className="card__title">Backend</p> */}
        </div>
      </div>
      <div className="card card_three">
        <div
          className="card__bg"
          ref={el => (backgroundsRef.current[2] = el)}
        >
          <p className="sound">Meow!</p>
        </div>
        <img
          alt="card_image"
          className="card__img"
          ref={el => (imagesRef.current[2] = el)}
          src={se}
        />
        <div className="card__text">
          {/* <p className="card__title">Other</p> */}
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
