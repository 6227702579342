import React, { useEffect, useRef } from "react";
import "./SoftwareSkill.scss";
import { skillsSection } from "../../portfolio";

export default function SoftwareSkill() {
  const skillRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target); // Stop observing once visible
        }
      });
    }, {
      threshold: 0.1 // Adjust if needed
    });

    skillRefs.current.forEach(skill => {
      if (skill) observer.observe(skill);
    });

    return () => {
      skillRefs.current.forEach(skill => {
        if (skill) observer.unobserve(skill);
      });
    };
  }, []);

  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {skillsSection.softwareSkills.map((skills, i) => {
            return (
              <li
                key={i}
                className="software-skill-inline"
                ref={el => skillRefs.current[i] = el} // Attach ref here
                name={skills.skillName}
              >
                <i style={{ color: skills.color }} className={skills.fontAwesomeClassname}></i>
                <p>{skills.skillName}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
