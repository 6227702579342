import React, {useContext, useState} from "react";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/landingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import {illustration, greeting} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import Typed from "typed.js";
import Hobby from "../hobby/Hobby";
import AnimatedLetters from "../../components/AnimatedLetters/AnimatedText";
import CardComponent from "../../components/CardComponent/CardComponent.jsx";

export default function Greeting() {
  const [showModal, setShowModal] = useState(false);
  const el = React.useRef(null);

  const [letterClass, setLetterClass] = useState("text-animate");
  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["<i>Hello, I'm Yi Chen</i>", "Let's connect!"],
      typeSpeed: 100,
      loop: true
    });

    return () => {
      typed.destroy();
    };
  }, []);
  React.useEffect(() => {
    setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 3000);
  }, []);
  const {isDark} = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="App"></div>
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <div style={{height: "80px"}}>
                <h1
                  className={
                    isDark ? "dark-mode greeting-text" : "greeting-text"
                  }
                  ref={el}
                ></h1>
              </div>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                A passionate Full-stack Software Engineer and AI Application
                Developer.
              </p>
            
            

              <SocialMedia />
              <div className="button-greeting-div">
                <Button text="Contact me" href="#contact" />

                <a
                  href={require("./YI CHEN - RESUME.pdf")}
                  download="YI CHEN - RESUME"
                  className="download-link-button"
                >
                  <Button text="Download my resume" />
                </a>
                <button
                  className="main-button"
                  onClick={() => setShowModal(true)}
                >
                  ABOUT ME
                </button>

                <Hobby showModal={showModal} setShowModal={setShowModal} />
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
            <img
              alt="man sitting on table"
              src={require("../../assets/images/profile.png")}
            ></img>
          </div>
        </div>
      </div>
    </Fade>
  );
}
