import React from "react";
import "./Progress.scss";
import {illustration, techStack} from "../../portfolio";
import {Fade, Slide} from "react-reveal";
import Build from "../../assets/lottie/build";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";

export default function StackProgress() {
  if (techStack.viewSkillBars) {
    return (
      <Slide bottom duration={1000} distance="20px">
        <div className="skills-container  ">
          <div className="skills-bar">
            <h1 className="skills-heading">PROFICIENCY</h1>
            {techStack.experience.map((exp, i) => {
              const progressStyle = {
                width: exp.progressPercentage
              };
              return (
                <div key={i} className="skill">
                  <p>{exp.Stack}</p>
                  <div className="meter">
                    <span style={progressStyle}></span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="skills-image">
          
              <img
              style={{height:"75vh",borderRadius:"2rem"}}
                alt="Skills"
                src={require("../../assets/images/proficiency.jpg")}
              />
         
          </div>
        </div>
      </Slide>
    );
  }
  return null;
}
