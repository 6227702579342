import React, {useContext} from "react";
import "./SplashScreen.css";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import {greeting, splashScreen} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import landingImg from "../../../src/assets/images/landing1.jpg"
export default function SplashScreen() {
  const {isDark} = useContext(StyleContext);
  return (
    <div className={isDark ? "dark-mode splash-container" : "splash-container"}>
      <div className="splash-animation-container">
        <h1>
         Loading Yi Chen's Portfolio...
        </h1>
      </div>
      <div className="splash-title-container">
       <img style={{height:"60vh",borderRadius:"2rem"}} src={landingImg} alt="Me & My Cat"/>
      </div>
    </div>
  );
}
